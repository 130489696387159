import {useKeycloak} from "@react-keycloak/web";
import {Navigate} from "react-router-dom";

const AuthRedirect = () => {
    const { keycloak } = useKeycloak();
    // Check if Keycloak is ready and the user is authenticated
    console.log("Keyclock is authenticated is " +keycloak.authenticated)
    if (!keycloak) {
        return <h2 style={{ textAlign: "center", margin: "10%" }}>Loading...</h2>; // Loading state if Keycloak isn't ready
    }
    if (!keycloak.authenticated) {
        console.log("NOT AUTHNETICATED")
        // Show login prompt if not authenticated
        return <h2 style={{ textAlign: "center", margin: "10%" }}>Please login to access the app</h2>;
    }
    // Check if the user is authenticated
    if (keycloak.authenticated) {
        // Check for roles
        if (keycloak.hasResourceRole('annotator')) {
            return <Navigate to="/annotate" />;
        } else if (keycloak.hasResourceRole('researcher')) {
            return <Navigate to="/main" />;
        }
    }

    return null; // Render nothing if not authenticated or no valid role
};
export default AuthRedirect;